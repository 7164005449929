<template>
  <div
    v-if="!shouldRender"
    v-intersection-observer="onIntersectionObserver"
    class="flex justify-between gap-2 px-2 py-1 items-center w-full h-[90px] col-span-full"
  >
    <div class="w-full gap-2 min-w-0 flex">
      <div class="w-full">
        <h1 class="text-md truncate text-white">Loading</h1>
        <p class="text-gray-500">please wait</p>
      </div>
    </div>
  </div>
  <template v-else>
    <div
      v-tooltip="studyIssues"
      class="cursor-pointer sticky left-0 z-50 bg-white border-l-[16px] border-l-white"
      @click="expandCard"
      @mouseover="onMouseOver"
      @mouseout="onMouseOut"
    >
      <DropZoneFileUpload
        class="w-full h-full"
        :accepted-file-types="['application/pdf', 'pdf']"
        @file-dropped="uploadStudyPdfFile"
      >
        <template #default="{ hasFileDraggedOver }">
          <div
            ref="expandablePanelElement"
            class="absolute left-0"
            style="top: -74px"
          />
          <div
            class="relative border border-[#D9E4EA] h-full"
            :class="{
              'bg-azureish-white/25': isHoveredOver,
              '!border-green-600 ': hasFileDraggedOver,

              duplicate: study.parentStudyId && screeningStage,
              'maybe-dupllicate': study.maybeParentStudyId && screeningStage,
              'duplication-parent': isParentStudy && screeningStage,
              quarantined: study.state === StudyState.QUARANTINED,
            }"
          >
            <div class="py-3 dark:border-[#345C7C] px-4 flex flex-col shrink-0">
              <ExpansionPanel
                :model-value="expansionPanel.isShown.value"
                disable-close-animation
                disable-open-animation
              >
                <template #activator>
                  <div class="space-y-2">
                    <div
                      class="inline-flex items-start justify-between w-full gap-2 min-w-0"
                    >
                      <div class="flex gap-2 items-start">
                        <div class="flex items-center gap-2">
                          <StudyFavoriteButton
                            :study="study"
                            :is-readonly="review.isReviewReadonly.value"
                            @toggle-favorite="toggleFavorite"
                          />
                        </div>
                        <div class="w-full min-w-0 space-y-2">
                          <div
                            class="flex justify-between gap-2 w-full sticky bottom-0 right-0 text-base"
                          >
                            <!-- eslint-disable vue/no-v-html -->
                            <p
                              v-if="study.title"
                              class="font-medium"
                              v-html="formattedTitle"
                            />
                            <p v-else class="text-sm text-gray-400 w-full">
                              Title not available
                            </p>
                          </div>
                        </div>
                      </div>

                      <FloatingMenu v-if="!review.isReviewReadonly.value">
                        <template #activator="{ show }">
                          <button @click.stop="show">
                            <EllipsisVerticalIcon class="w-5 h-auto" />
                          </button>
                        </template>
                        <template #content>
                          <div>
                            <StudyEdit
                              :id="study.id"
                              :meta-data="{
                                abstract: study.abstract ?? '',
                                authors: [...study.authors],
                                doi: study.doi ?? '',
                                title: study.title ?? '',
                                issueNumber: study.issueNumber ?? '',
                                journal: study.journal ?? '',
                                pagesNumber: study.pagesNumber ?? '',
                                publishYear: study.publishYear ?? '',
                                volumeNumber: study.volumeNumber ?? '',
                              }"
                            />

                            <SubmitComplaint :study="study" />
                          </div>
                        </template>
                      </FloatingMenu>
                    </div>
                    <div class="flex justify-between w-full">
                      <div class="text-[#617177] text-sm inline-flex">
                        <div
                          v-if="
                            study.eventType &&
                            (study.search?.source.id ===
                              BuiltInImportSourceId.MAUDE ||
                              study.search?.source.id ===
                                BuiltInImportSourceId.OPEN_FDA)
                          "
                          class="flex gap-2 flex-wrap"
                        >
                          <div
                            v-tooltip="
                              !expansionPanel.isShown.value
                                ? study.eventType
                                : ''
                            "
                            class="text-xs px-2 py-1 bg-[#E1E4E5] text-black rounded-md h-6"
                            :class="{
                              'max-w-[100px]': !expansionPanel.isShown.value,
                            }"
                          >
                            <p class="truncate">{{ study.eventType }}</p>
                          </div>
                        </div>
                        <div
                          v-else-if="
                            study.studyId &&
                            study.search?.source.id ===
                              BuiltInImportSourceId.ICTRP
                          "
                          v-tooltip="
                            !expansionPanel.isShown.value ? study.studyId : ''
                          "
                          class="text-xs px-2 py-1 bg-[#E1E4E5] text-black rounded-md h-6"
                          :class="{
                            'max-w-[100px]': !expansionPanel.isShown.value,
                          }"
                        >
                          <p class="truncate">{{ study.studyId }}</p>
                        </div>
                        <div v-else class="flex gap-2 flex-wrap">
                          <div
                            v-for="(author, index) in authorsToDisplay(
                              formattedAuthors,
                            )"
                            :key="author + index"
                            v-tooltip="
                              !expansionPanel.isShown.value ? author : ''
                            "
                            class="text-xs px-2 py-1 bg-[#E1E4E5] text-black rounded-md h-6"
                            :class="{
                              'max-w-[94px]': !expansionPanel.isShown.value,
                            }"
                          >
                            <p class="truncate">{{ author }}</p>
                          </div>
                          <button
                            v-if="study.authors && study.authors.length > 5"
                            v-tooltip="'Click to list all authors'"
                            class="text-xs px-1 py-1 bg-[#E1E4E5] rounded-md text-black h-6"
                            @click.stop="
                              ;[
                                (displayAllAuthors = !displayAllAuthors),
                                authorsToDisplay(formattedAuthors),
                              ]
                            "
                          >
                            ...
                          </button>
                        </div>
                      </div>
                      <div v-if="!expansionPanel.isShown.value">
                        <button
                          @click.stop="
                            !!study.pdfFile?.path
                              ? donwloadStudyPdfFile(study.id)
                              : ''
                          "
                        >
                          <PdfIcon
                            class="w-6 h-6 text-primary"
                            :class="{
                              'text-green-600': isMatchFoundInPdf,
                              '!text-black/30': !study.pdfFile?.path,
                              'animate-bounce !text-primary': isPdfFetching,
                            }"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </template>
                <template #default>
                  <div class="w-full">
                    <div class="my-6 text-sm flex justify-between gap-12">
                      <div class="flex items-center gap-2">
                        <FloatingMenu
                          placement="bottom"
                          :offset="[0, 5]"
                          :arrow="true"
                        >
                          <template #activator="{ show, isShown }">
                            <div
                              v-if="isPdfFetching"
                              v-tooltip="'Fetching for PDF'"
                            >
                              <LoadingIcon class="w-5 h-5" />
                            </div>
                            <div v-else class="flex h-7">
                              <button
                                class=""
                                :class="[
                                  !!study.pdfFile?.path
                                    ? 'rounded-l-md border border-gray-300'
                                    : 'text-gray-500 cursor-not-allowed',
                                ]"
                                @click.stop="
                                  !!study.pdfFile?.path
                                    ? donwloadStudyPdfFile(study.id)
                                    : ''
                                "
                              >
                                <PdfIcon
                                  class="w-6 h-6 text-primary"
                                  :class="{
                                    'text-green-600': isMatchFoundInPdf,
                                    '!text-black/30': !study.pdfFile?.path,
                                  }"
                                />
                              </button>

                              <button
                                v-if="!!study.pdfFile?.path"
                                class="rounded-r-md border border-gray-300 p-1 hover:bg-black/5"
                                @click.stop="show"
                              >
                                <ChevronDownIcon
                                  class="w-4 h-4 stroke-[3] transition-all"
                                  :class="{
                                    'rotate-180': !isShown,
                                  }"
                                />
                              </button>
                            </div>
                          </template>

                          <template #content>
                            <div
                              class="dark:bg-[#103047] dark:text-white/70 dark:border-white/10"
                              :class="{
                                'bg-azureish-white/25': isHoveredOver,
                              }"
                            >
                              <button
                                v-tooltip="
                                  !!study.pdfFile?.path
                                    ? 'Click to download PDF'
                                    : ''
                                "
                                class="flex w-full px-4 py-2 hover:bg-black/5 dark:hover:bg-[#344F62]"
                                :class="[
                                  !!study.pdfFile?.path
                                    ? ''
                                    : 'text-gray-500 cursor-not-allowed',
                                ]"
                                @click.stop="
                                  !!study.pdfFile?.path
                                    ? donwloadStudyPdfFile(study.id)
                                    : ''
                                "
                              >
                                Download
                              </button>
                              <FloatingMenu
                                v-if="
                                  !!study.pdfFile?.path &&
                                  !review.isReviewReadonly.value
                                "
                                placement="right-start"
                                :offset="[0, 0]"
                              >
                                <template #activator="{ show }">
                                  <button
                                    v-tooltip="'Click to delete PDF'"
                                    class="flex w-full px-4 py-2 hover:bg-black/5 dark:hover:bg-[#344F62]"
                                    :class="[
                                      !!study.pdfFile?.path
                                        ? ''
                                        : 'text-gray-500 cursor-not-allowed',
                                    ]"
                                    @click.stop="show"
                                  >
                                    Delete
                                  </button>
                                </template>
                                <template #content="{ hide }">
                                  <div class="w-96 p-4">
                                    <p class="mb-2">
                                      are you sure you want to delete the pdf
                                      file?
                                    </p>
                                    <div>
                                      <button
                                        class="bg-neutral-500 hover:bg-neutral-600 text-white py-1 px-2 mr-1"
                                        @click.stop="hide"
                                      >
                                        cancel
                                      </button>
                                      <button
                                        class="bg-red-500 hover:bg-red-600 text-white py-1 px-2"
                                        @click.stop="
                                          deletePdfFile(study?.id, hide)
                                        "
                                      >
                                        delete
                                      </button>
                                    </div>
                                  </div>
                                </template>
                              </FloatingMenu>
                            </div>
                          </template>
                        </FloatingMenu>
                      </div>
                      <div class="flex items-center gap-2">
                        <a
                          class="underline flex items-center"
                          :href="study.url"
                          target="_blank"
                          @click.stop
                        >
                          <div
                            v-tooltip="
                              `${study.search?.source.name} (${searchIndex})`
                            "
                          >
                            <template v-if="sourceIconUrl">
                              <img
                                v-show="isSourceLogoValid"
                                :key="sourceIconUrl"
                                class="w-5 h-5"
                                :src="sourceIconUrl"
                                alt="source logo"
                                @load="
                                  ({ target: imageElement }) =>
                                    checkImage(imageElement as HTMLImageElement)
                                "
                                @error="
                                  ({ target: imageElement }) =>
                                    handleImageError(
                                      imageElement as HTMLImageElement,
                                    )
                                "
                              />
                              <div v-show="!isSourceLogoValid" class="flex">
                                <span
                                  class="w-6 h-6 p-0.5 border-primary/50 border-2 text-primary/80 rounded-full"
                                >
                                  <DatabaseIcon class="w-4 h-4" />
                                </span>
                              </div>
                            </template>
                            <div v-else class="flex">
                              <span
                                class="w-6 h-6 p-0.5 border-primary/50 border-2 text-primary/80 rounded-full"
                              >
                                <DatabaseIcon class="w-4 h-4" />
                              </span>
                            </div>
                          </div>
                        </a>

                        <div
                          v-if="!!study.doi"
                          class="flex items-center text-gray-400 hover:brightness-125"
                        >
                          <a
                            class="flex items-center underline"
                            :href="study.DoiLink"
                            target="_blank"
                            @click.stop
                          >
                            <ExternalLinkIcon class="w-5 h-5" /> DOI
                          </a>
                        </div>
                        <div class="text-[#53676E] flex items-center">
                          id:
                          <p v-html="formattedId"></p>
                        </div>
                      </div>
                    </div>

                    <div class="flex justify-between gap-2">
                      <div class="w-full sticky bottom-0 right-0">
                        <div
                          class="px-2 py-1 text-sm text-[#53676F] dark:bg-[#5C7282] dark:text-white block bg-[#E4F0F6] w-full min-w-0"
                        >
                          <p
                            v-if="study.abstract"
                            v-html="formattedAbstract"
                          ></p>
                          <p
                            v-else
                            class="text-gray-400 dark:text-gray-200 block w-full"
                          >
                            Abstract not available
                          </p>
                        </div>
                      </div>
                      <div
                        v-if="!study.abstract || !isAbstractValid"
                        class="flex justify-between"
                        @click.stop="
                          markStudyAbstractAsNotAvailable(
                            study.id,
                            'Abstract not available',
                          )
                        "
                      >
                        <FloatingMenu
                          trigger="hover"
                          :offset="[0, 10]"
                          placement="right"
                        >
                          <template #activator>
                            <div
                              class="flex w-full px-2 py-1 sticky bottom-0 right-0 hover:brightness-110"
                            >
                              <ExclamationIcon
                                class="fill-yellow-300 text-yellow-700 w-5 h-5"
                              />
                            </div>
                          </template>
                          <template #content>
                            <div class="px-2 py-1">
                              Click here to confirm that study abstract is not
                              available
                            </div>
                          </template>
                        </FloatingMenu>
                      </div>
                    </div>
                  </div>
                </template>
              </ExpansionPanel>
            </div>
          </div>
        </template>
      </DropZoneFileUpload>
    </div>
    <div
      v-if="screeningStage"
      class="border border-[#D9E4EA] py-3 dark:border-[#345C7C] px-2 items-center shrink-0 max-w-[399px]"
      :class="{
        'bg-azureish-white/25': isHoveredOver,
      }"
      @mouseover="onMouseOver"
      @mouseout="onMouseOut"
    >
      <div class="flex gap-2">
        <StudyDuplicationButton
          :study="study"
          :is-loading="isLoading"
          @mark-as-duplicate="markStudyAsDuplicate"
          @mark-as-not-duplicate="markStudyAsNotDuplicate"
        />
        <StudyTitleAndAbstractScreeningButton
          v-if="
            review.entity.value?.plan?.screeningPlan?.titleAndAbstractCriteria
              .length ?? 0 > 0
          "
          :study="study"
          :is-loading="isLoading"
          :is-abstract-valid="isAbstractValid"
          @clear-title-and-abstract-screening="clearTitleAndAbstractScreening"
          @set-title-and-abstract-screening="setTitleAndAbstractScreening"
        />
        <StudyFullTextScreeningButton
          :study="study"
          :is-loading="isLoading"
          :is-abstract-valid="isAbstractValid"
          @clear-full-text-screening="clearFullTextScreening"
          @set-full-text-screening="setFullTextScreening"
        />
      </div>
      <div v-if="expansionPanel.isShown.value" class="mb-4 mt-8">
        <div class="w-full">
          <span
            v-if="study.state === StudyState.INCLUDED"
            class="bg-primary-green px-2 py-1 text-black rounded-md text-sm"
          >
            Included
          </span>
          <div
            v-else-if="study.isDuplicate"
            v-tooltip="duplicationParentStudy?.title"
            class="w- truncate bg-red-500 px-2 py-1 rounded-md text-white text-xs"
          >
            Duplicate of (ID:
            {{ duplicationParentStudy?.id }}) :
            {{ duplicationParentStudy?.title }}
          </div>

          <span
            v-else-if="exclusionCriterion"
            class="bg-red-500 px-2 py-1 rounded-md text-white text-xs"
          >
            {{ exclusionCriterion }}
          </span>
          <span
            v-else
            class="bg-neutral-900 px-2 py-1 rounded-md text-white text-xs"
          >
            Unscreened
          </span>
        </div>
      </div>
    </div>
    <StudyDesign
      v-if="props.studyDesignStage"
      :is-drawer-open="isDrawerOpen"
      :study="study"
      @toggle-drawer="emitToggleDrawer"
    />
    <StudyDesignDrawer
      v-if="props.studyDesignStage"
      :is-drawer-open="isDrawerOpen"
      :study="study"
    />
    <StudyAppraisal :study="study" />
    <AttributeExtraction
      v-if="props.synthesisStage"
      v-model="isHoveredOver"
      :is-expanded="expansionPanel.isShown.value"
      :study="study"
    />
  </template>
</template>

<script lang="ts" setup>
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import DropZoneFileUpload from '@app/components/Global/Inputs/DropZoneFileUpload.vue'
import ExclamationIcon from '@app/components/Icons/ExclamationIcon.vue'
import ExternalLinkIcon from '@app/components/Icons/ExternalLinkIcon.vue'
import PdfIcon from '@app/components/Icons/PdfIcon.vue'
import useExpansionPanel from '@app/composables/use-expansion-panel'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState, StudyState } from '@app/types'
import { applyHighlights } from '@app/utils/words-highlighter'
import { ReviewKey } from '@app/views/Review/use-review'
import { Study } from '@core/domain/models/study.model'
import { Id } from '@core/domain/types/id.type'
import { hideAll } from 'tippy.js'
import { computed, nextTick, ref } from 'vue'
import StudyAppraisal from './StudyAppraisal/StudyAppraisal.vue'
import StudyDuplicationButton from './StudyDuplicationButton.vue'
import StudyEdit from './StudyEdit.vue'
import StudyFavoriteButton from './StudyFavoriteButton.vue'
import StudyFullTextScreeningButton from './StudyFullTextScreeningButton.vue'
import StudyTitleAndAbstractScreeningButton from './StudyTitleAndAbstractScreeningButton.vue'
import SubmitComplaint from './SubmitComplaint.vue'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import ExpansionPanel from '@app/components/Global/ExpansionPanel.vue'
import { injectStrict } from '@app/utils/injectStrict'
import AttributeExtraction from '@app/views/Review/Execute/Studies/AttributeExtraction/AttributeExtraction.vue'
import { isUrlValid } from '@app/utils/urlValidation'
import DatabaseIcon from '@app/components/Icons/DatabaseIcon.vue'
import ChevronDownIcon from '@app/components/Icons/ChevronDownIcon.vue'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import LoadingIcon from '@app/components/Icons/LoadingIcon.vue'
import { vIntersectionObserver } from '@vueuse/components'
import { EllipsisVerticalIcon } from '@heroicons/vue/24/outline'
import StudyDesign from './StudyDesign/StudyDesign.vue'
import StudyDesignDrawer from './StudyDesign/StudyDesignDrawer.vue'

const review = injectStrict(ReviewKey)
const displayAllAuthors = ref(false)

const isHoveredOver = ref(false)

function onMouseOver() {
  isHoveredOver.value = true
}
function onMouseOut() {
  isHoveredOver.value = false
}

const props = withDefaults(
  defineProps<{
    study: Study
    studyDesignStage?: boolean
    screeningStage?: boolean
    synthesisStage?: boolean
    studyDesignDrawer?: boolean
    isDrawerOpen: boolean
  }>(),
  {
    study: () => new Study({}),
    synthesisStage: true,
  },
)

const emit = defineEmits<(e: 'toggle-drawer') => void>()

const emitToggleDrawer = () => {
  emit('toggle-drawer')
}

const searchIndex = `Search ${
  review.entity.value.getSearchIndex(props.study) + 1
}`

const isSourceLogoValid = ref(true)

const loading = useLoading()
const isLoading = ref(false)
const snackbar = useSnackbar()

const expansionPanel = useExpansionPanel()

const expandablePanelElement = ref<HTMLElement>()

const shouldRender = ref(false)

const isAbstractValid = computed(() => {
  return (
    (props.study.abstract?.[0] !== '.' ||
      props.study.abstract?.[1] !== '.' ||
      props.study.abstract?.[2] !== '.') &&
    (props.study.abstract?.[props.study.abstract.length - 1] !== '.' ||
      props.study.abstract?.[props.study.abstract.length - 2] !== '.' ||
      props.study.abstract?.[props.study.abstract.length - 3] !== '.')
  )
})

const isPdfFetching = computed(() => {
  return review.runningStudiesPdfImportsMap.value.has(props.study.id)
})

const sourceIconUrl = ref(
  isUrlValid(props.study.search?.source.url ?? '')
    ? `https://www.google.com/s2/favicons?domain=${encodeURIComponent(
        props.study.search?.source.url ?? '',
      )}&sz=64`
    : '',
)

function checkImage(target: HTMLImageElement) {
  const img = target

  if (img.naturalWidth === 5 && img.naturalHeight === 5) {
    isSourceLogoValid.value = false
  } else {
    isSourceLogoValid.value = true
  }
}

function handleImageError(target: HTMLImageElement) {
  target.src = '/sources-icons/custom-source.png'
}

const duplicationParentStudy = computed(() => {
  if (props.study.state !== StudyState.DUPLICATE) return null
  return review.entity.value.studies.find(
    (a) => a.id === props.study.parentStudyId,
  )
})

function onIntersectionObserver([
  { isIntersecting },
]: IntersectionObserverEntry[]) {
  if (!isIntersecting) return
  shouldRender.value = true
}

function authorsToDisplay(authors: string[]) {
  if (authors.length > 5 && !displayAllAuthors.value) {
    return authors.slice(0, 5)
  } else {
    return authors
  }
}

const formattedAbstract = computed(() => {
  let abstract = props.study.abstract
  if (!abstract) return ''

  if (review.filtering.terms.value.length > 0) {
    abstract = applyHighlights(abstract, review.filtering.terms.value)
  }
  if (props.study.search?.source.id !== BuiltInImportSourceId.MAUDE) {
    const abstractTitlesRegex = /\b([A-Z][A-Z\s]*:)/gim
    abstract = abstract.replaceAll('\n', '<br>')
    abstract = abstract.replace(abstractTitlesRegex, '<strong>$1</strong> ')
  }

  return abstract
})

const formattedTitle = computed(() => {
  let title = props.study.title
  if (!title) return ''

  if (review.filtering.terms.value.length > 0) {
    title = applyHighlights(title, review.filtering.terms.value)
  }
  return title
})

const formattedId = computed(() => {
  let id = props.study.id.toString()
  if (!id) return ''
  if (review.filtering.terms.value.length > 0) {
    id = applyHighlights(id, review.filtering.terms.value)
  }
  return id
})

const formattedAuthors = computed(() => {
  let authors = [...props.study.authors]
  if (!authors) return []
  if (review.filtering.terms.value.length > 0) {
    authors = authors.map((author) => {
      return applyHighlights(author, review.filtering.terms.value)
    })
  }
  return authors
})

const isMatchFoundInPdf = computed(() => {
  let isMatchFound = false
  if (review.filtering.terms.value.length > 0) {
    const searchTagsRegexString =
      review.filtering.terms.value.map((t) => `(?=.*${t})`).join('') + '.*'
    const searchRegex = new RegExp(`${searchTagsRegexString}`, 'i')
    isMatchFound = searchRegex.test(props.study.pdfContent ?? '')
  }

  return isMatchFound
})

const exclusionCriterion = computed(() => {
  if (
    props.study.titleAndAbstractScreening !== '' &&
    props.study.titleAndAbstractScreening !== 'Included'
  )
    return props.study.titleAndAbstractScreening
  if (
    props.study.fullTextScreening !== '' &&
    props.study.fullTextScreening !== 'Included'
  )
    return props.study.fullTextScreening
  return ''
})

async function markStudyAsDuplicate(parentStudyId: Id) {
  try {
    startLoading()
    await review.markStudyAsDuplicate(props.study.id, parentStudyId)
    snackbar.show(
      SnackbarState.SUCCESS,
      'study marked as duplicate successfully',
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    stopLoading()
  }

  stopLoading()
}

async function markStudyAsNotDuplicate() {
  try {
    startLoading()
    await review.markStudyAsNotDuplicate(props.study.id)
    snackbar.show(
      SnackbarState.SUCCESS,
      'study marked as not duplicate successfully',
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

async function setTitleAndAbstractScreening(key: string) {
  if (
    !props.study.pdfFile &&
    review.entity.value?.plan?.screeningPlan?.titleAndAbstractCriteria
      ?.length === 0 &&
    key === 'Included'
  ) {
    snackbar.show(
      SnackbarState.ERROR,
      'You must upload the study to continue. Please upload a clean document without highlights or comments',
    )
    return
  }
  try {
    startLoading()
    await review.setStudyTitleAndAbstractScreening(props.study.id, key)
    snackbar.show(
      SnackbarState.SUCCESS,
      'successfully updated title and abstract screening',
    )
    hideAll({ duration: 0 })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

async function clearTitleAndAbstractScreening() {
  try {
    startLoading()
    await review.clearStudyTitleAndAbstractScreening(props.study.id)
    snackbar.show(
      SnackbarState.SUCCESS,
      'successfully cleared title and abstract screening',
    )
    hideAll({ duration: 0 })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

async function setFullTextScreening(key: string) {
  try {
    startLoading()
    await review.setStudyFullTextScreening(props.study.id, key)
    snackbar.show(
      SnackbarState.SUCCESS,
      'successfully updated full text screening',
    )
    hideAll({ duration: 0 })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

async function clearFullTextScreening() {
  try {
    startLoading()
    await review.clearStudyFullTextScreening(props.study.id)
    snackbar.show(
      SnackbarState.SUCCESS,
      'successfully cleared full text screening',
    )
    hideAll({ duration: 0 })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

async function toggleFavorite() {
  try {
    startLoading()
    if (!props.study.isFavorite) {
      await review.favoriteStudy(props.study.id)
      snackbar.show(
        SnackbarState.SUCCESS,
        'study marked as favorite successfully',
      )
    } else {
      await review.unfavoriteStudy(props.study.id)
      snackbar.show(
        SnackbarState.SUCCESS,
        'study marked as unfavorite successfully',
      )
    }
    hideAll({ duration: 0 })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

async function uploadStudyPdfFile(file: File) {
  try {
    startLoading()
    await review.uploadStudyPdfFile(props.study.id, file)
    snackbar.show(SnackbarState.SUCCESS, 'pdf file uploaded successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

async function deletePdfFile(studyId: Id, cb: () => void = () => {}) {
  try {
    startLoading()
    await review.deleteStudyPdfFile(studyId)
    snackbar.show(SnackbarState.SUCCESS, 'pdf file deleted successfully')
    cb()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    stopLoading()
  }
}

function startLoading() {
  loading.start()
  isLoading.value = true
}
function stopLoading() {
  loading.stop()
  isLoading.value = false
}

function isTextSelected() {
  return !!window?.getSelection()?.toString()
}

async function markStudyAbstractAsNotAvailable(studyId: Id, abstract: string) {
  try {
    startLoading()
    await review.editStudyAbstract(studyId, abstract)
    snackbar.show(
      SnackbarState.SUCCESS,
      'study abstract marked as not available successfully ',
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    stopLoading()
  }
}

function expandCard() {
  if (!isTextSelected()) {
    expansionPanel.toggle()
    if (expansionPanel.isShown.value) {
      nextTick(() =>
        expandablePanelElement.value!.scrollIntoView({
          behavior: 'smooth',
        }),
      )
    }
  }
}

const studyIssues = computed(() => {
  let content = ''
  if (props.study.isDuplicate) return ''

  if (props.study.maybeDuplicate) {
    content += '\u{2022} Confirm/reject the duplication.<br>'
  }
  if (
    props.study.search?.source.id !== 'clinical-trials-gov' &&
    props.study.search?.source.id !== 'maude' &&
    props.study.search?.source.id !== 'ictrp' &&
    (!props.study.publishYear || props.study.authors.length === 0)
  ) {
    content += '\u{2022} Author is missing and Publish year is missing.<br>'
  }
  if (!props.study.title) {
    content += '\u{2022} Title is missing.<br>'
  }
  if (!props.study.abstract || !isAbstractValid.value) {
    content +=
      '\u{2022} Abstact missing or incomplete. Confirm/modify abstract.<br>'
  }
  return content
})

const studyFileName: string = (() => {
  let fileName = ''
  if (props.study.authors?.[0]?.split(',')?.[0] && props.study.publishYear) {
    fileName =
      props.study.authors?.[0]?.split(',')?.[0] + '-' + props.study.publishYear
  } else {
    fileName = 'study-id-' + props.study.id.toString()
  }

  return fileName.replace(/[^a-z0-9\s]/gi, '-').toLowerCase()
})()

async function donwloadStudyPdfFile(studyId: Id) {
  try {
    loading.start()
    const studyPdf = await review.getStudyPdfFile(studyId)
    const downloadElement = document.createElement('a')

    const pdfFileName = studyFileName + '.pdf'
    downloadElement.style.display = 'none'
    downloadElement.href = window.URL.createObjectURL(studyPdf)
    downloadElement.download = pdfFileName
    document.body.appendChild(downloadElement)
    downloadElement.click()
    window.URL.revokeObjectURL(downloadElement.href)
    downloadElement.remove()

    snackbar.show(SnackbarState.SUCCESS, 'pdf file downloaded successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    stopLoading()
  }
}

const isParentStudy = computed(() => {
  return (
    review.parentStudyIds.value.has(props.study.id) ||
    review.maybeParentStudyIds.value.has(props.study.id)
  )
})
</script>

<style>
.duplication-parent::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  bottom: -2px;
  border-left: 6px solid;
  @apply border-violet-600;
  z-index: 1;
}

.duplication-parent::after {
  content: '';
  position: absolute;
  left: -2px;
  top: calc(50% - 5px);
  width: 10px;
  height: 10px;
  border-radius: 100%;
  @apply bg-violet-600;
}

.duplicate::before {
  content: '';
  position: absolute;
  left: 0;
  top: -2px;
  bottom: 0;
  border-left: 6px solid;
  @apply border-violet-600;
  z-index: 1;
}
.maybe-dupllicate::before {
  content: '';
  position: absolute;
  left: 0;
  top: -2px;
  bottom: 0;
  width: 5px;
  background-image: linear-gradient(
    45deg,
    #7c3aed 33.33%,
    #ffffff 33.33%,
    #ffffff 50%,
    #7c3aed 50%,
    #7c3aed 83.33%,
    #ffffff 83.33%,
    #ffffff 100%
  );
  background-size: 21.21px 21.21px;
  z-index: 1;
}

.quarantined {
  background-image: linear-gradient(
    135deg,
    #f2e42719 25%,
    #00000019 25%,
    #00000019 50%,
    #f2e42719 50%,
    #f2e42719 75%,
    #00000019 75%,
    #00000019 100%
  );

  background-size: 113.14px 113.14px;
}

.maybe-dupllicate {
  background-image: linear-gradient(
    135deg,
    #f2e42719 25%,
    #00000019 25%,
    #00000019 50%,
    #f2e42719 50%,
    #f2e42719 75%,
    #00000019 75%,
    #00000019 100%
  );

  background-size: 113.14px 113.14px;
}
</style>
