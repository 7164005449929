export enum BuiltInImportSourceId {
  PUBMED = 'pubmed',
  GOOGLE_SCHOLAR = 'google-scholar',
  DIMENSIONS_AI = 'dimensions-ai',
  COCHRANE = 'cochrane',
  HAND_SEARCH = 'hand-search',
  CITATION_SEARCH = 'citation-search',
  MAUDE = 'maude',
  OPEN_ALEX = 'open-alex',
  MDCG = 'mdcg',
  OPEN_FDA = 'open-fda',
  ICTRP = 'ictrp',
  PMC = 'pmc',
  BFARM = 'bfarm',
  CENELEC = 'cenelec',
}
