<template>
  <AddImportSourceToPlanV1 v-if="!isCuttingEdge" />
  <AddImportSourceToPlanV2 v-else />
</template>
<script setup lang="ts">
import AddImportSourceToPlanV1 from './AddImportSourceToPlanV1.vue'
import AddImportSourceToPlanV2 from './AddImportSourceToPlanV2.vue'
import { DevToolboxKey } from '@app/injectionKeys'
import { injectStrict } from '@app/utils/injectStrict'

const { isCuttingEdge } = injectStrict(DevToolboxKey)
</script>
