import { ref } from 'vue'

export default () => {
  const isScreeningStageActive = ref(true)

  const isStudyDesignStageActive = ref(false)

  const isAppraisalStageActive = ref(false)

  const isSynthesisStageActive = ref(false)

  function toggleScreeningStage() {
    isScreeningStageActive.value = !isScreeningStageActive.value
  }

  function toggleAppraisalStage() {
    isAppraisalStageActive.value = !isAppraisalStageActive.value
  }

  function toggleSynthesisStage() {
    isSynthesisStageActive.value = !isSynthesisStageActive.value
  }
  function toggleStudyDesignStage() {
    isStudyDesignStageActive.value = !isStudyDesignStageActive.value
  }

  return {
    toggleScreeningStage,
    toggleAppraisalStage,
    toggleSynthesisStage,
    toggleStudyDesignStage,
    isStudyDesignStageActive,
    isScreeningStageActive,
    isAppraisalStageActive,
    isSynthesisStageActive,
  }
}
