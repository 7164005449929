<template>
  <div class="border border-[#D9E4EA] items-center py-3">
    <div class="flex gap-2 divide-x divide-gray-200 px-3">
      <div class="flex gap-1 items-center justify-center">
        <button @click.stop="toggleStudyDesignStatusCheckbox()">
          <Checkbox
            v-model="studyDesignNotApplicableCheckbox"
            class="w-5 h-5"
          ></Checkbox>
        </button>
        <p class="text-primary">N/A</p>
      </div>
      <div class="px-2">
        <button
          :class="{
            'bg-orange-300': isDrawerOpen,
            'cursor-not-allowed bg-gray-100':
              study.studyDesignStatus === StudyDesignStatus.notApplicable,
          }"
          class="gap-1 px-3 py-2 text-sm rounded flex items-center justify-center bg-blue-200 text-blue-800"
          @click="
            study.studyDesignStatus === StudyDesignStatus.applicable
              ? emitToggleDrawer()
              : ''
          "
        >
          Study design
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { Study } from '@core/domain/models/study.model'
import { HttpException } from '@core/exceptions/http.exception'
import { StudyDesignStatus } from '@core/domain/types/studyDesignStatus.type'
import Checkbox, {
  CheckboxState,
} from '@app/components/Global/Inputs/Checkbox.vue'
import { ref } from 'vue'

const props = defineProps<{
  study: Study
  isDrawerOpen: boolean
}>()

const emit = defineEmits<(e: 'toggle-drawer') => void>()

const emitToggleDrawer = () => {
  emit('toggle-drawer')
}

const review = injectStrict(ReviewKey)

const studyDesignNotApplicableCheckbox = ref<CheckboxState>(
  props.study.studyDesignStatus === StudyDesignStatus.applicable
    ? CheckboxState.uncheked
    : CheckboxState.checked,
)

async function toggleStudyDesignStatusCheckbox() {
  if (studyDesignNotApplicableCheckbox.value === CheckboxState.checked) {
    await setStudyDesignStatus(props.study.id, StudyDesignStatus.applicable)
    studyDesignNotApplicableCheckbox.value = CheckboxState.uncheked
  } else {
    await setStudyDesignStatus(props.study.id, StudyDesignStatus.notApplicable)
    studyDesignNotApplicableCheckbox.value = CheckboxState.checked
  }
}

const snackbar = useSnackbar()
const loading = useLoading()

async function setStudyDesignStatus(
  studyId: number,
  studyDesignStatus: StudyDesignStatus,
) {
  try {
    loading.start()
    await review.setStudyDesignStatus(studyId, studyDesignStatus)
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}
</script>
