import { ImportSource } from '@core/domain/models/import-source.model'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'

export class BuiltInImportSourcesRepository {
  async find(): Promise<ImportSource[]> {
    const importSources = [
      new ImportSource({
        id: BuiltInImportSourceId.PUBMED,
        name: 'PubMed',
        type: ImportSourceType.DATABASE,
        description:
          'Country: USA\nDatabase type: Literature database\nIndexing type: Title and abstract\nLaunched: 1996\nSize (approx): 36 million articles',
        url: 'https://pubmed.ncbi.nlm.nih.gov/',
        order: 1,
      }),
      new ImportSource({
        id: BuiltInImportSourceId.GOOGLE_SCHOLAR,
        name: 'Google Scholar',
        type: ImportSourceType.OTHER_SOURCE,
        description:
          'Country: USA\nDatabase type: Search engine\nIndexing type: Full text\nLaunched: 2004\nSize (approx): 400 million articles',
        url: 'https://scholar.google.com/',
        order: 2,
      }),
      new ImportSource({
        id: BuiltInImportSourceId.DIMENSIONS_AI,
        name: 'Dimensions',
        type: ImportSourceType.DATABASE,
        description:
          'Country: UK\nDatabase type: Literature database\nIndexing type: Full text\nLaunched: 2010\nSize (approx): 300 million articles',
        url: 'https://www.dimensions.ai/',
        order: 3,
      }),
      new ImportSource({
        id: BuiltInImportSourceId.COCHRANE,
        name: 'Cochrane Database of Systematic Reviews',
        type: ImportSourceType.DATABASE,
        description:
          'Country: USA\nDatabase type: Literature database\nIndexing type: Full text\nLaunched: 1996\nSize (approx): 9000 articles',

        url: 'https://www.cochranelibrary.com/cdsr/reviews',
        order: 4,
      }),
      new ImportSource({
        id: BuiltInImportSourceId.HAND_SEARCH,
        name: 'Hand search',
        type: ImportSourceType.HAND_SEARCH,
        description: '',
        url: '',
        order: 5,
      }),
      new ImportSource({
        id: BuiltInImportSourceId.CITATION_SEARCH,
        name: 'Citation search',
        type: ImportSourceType.CITATION_SEARCH,
        description: '',
        url: '',
        order: 6,
      }),
      new ImportSource({
        id: BuiltInImportSourceId.MAUDE,
        name: 'MAUDE',
        type: ImportSourceType.DATABASE,
        description: '',
        url: 'https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfmaude/TextSearch.cfm',
        order: 7,
      }),
      new ImportSource({
        id: BuiltInImportSourceId.OPEN_ALEX,
        name: 'Open Alex',
        type: ImportSourceType.DATABASE,
        description:
          'Country: USA\nDatabase type: Literature database\nIndexing type: Title and abstract + full text of open-access articles\nLaunched: 2021\nSize (approx): 250 million articles',
        url: 'https://openalex.org/',
        order: 8,
      }),
      new ImportSource({
        id: BuiltInImportSourceId.MDCG,
        name: 'MDCG (Medical Devices Cordinating Group)',
        type: ImportSourceType.DATABASE,
        description:
          'Country: EU  Regulatory body: Medical Device Coordination Group (MDCG)  Responsibilities: Provides guidance and oversees the implementation of medical device regulations within the EU  Chair: European Commission  Participants: Competent authorities, stakeholders',
        url: 'https://health.ec.europa.eu/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en',
        order: 9,
      }),
      new ImportSource({
        id: BuiltInImportSourceId.OPEN_FDA,
        name: 'Open FDA',
        type: ImportSourceType.DATABASE,
        description: '',
        url: 'https://open.fda.gov/',
        order: 10,
      }),
      new ImportSource({
        id: BuiltInImportSourceId.ICTRP,
        name: 'International Clinical Trials Registry Platform',
        type: ImportSourceType.DATABASE,
        description: '',
        url: 'https://trialsearch.who.int/',
        order: 11,
      }),
      new ImportSource({
        id: BuiltInImportSourceId.PMC,
        name: 'PMC (PubMed Central)',
        type: ImportSourceType.DATABASE,
        description: '',
        url: 'https://www.ncbi.nlm.nih.gov/pmc/',
        order: 12,
      }),
      new ImportSource({
        id: BuiltInImportSourceId.BFARM,
        name: 'BfArM',
        type: ImportSourceType.DATABASE,
        description: '',
        url: 'https://www.bfarm.de/SiteGlobals/Forms/Suche/EN/Servicesuche_Formular.html',
        order: 13,
      }),
      new ImportSource({
        id: BuiltInImportSourceId.CENELEC,
        name: 'CENELEC',
        type: ImportSourceType.DATABASE,
        description: '',
        url: 'https://standards.cencenelec.eu/dyn/www/f?p=CEN:105::RESET::::',
        order: 14,
      }),
    ]
    return importSources.map((d) => {
      const { type, ...source } = d
      return new ImportSource({
        ...source,
        type: type as ImportSourceType,
      })
    })
  }
}
