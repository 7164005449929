<template>
  <Drawer :model-value="isDrawerOpen">
    <div
      class="sticky top-0 left-0 py-2 px-4 w-[750px] flex-col space-y-1 overflow-hidden"
    >
      <div>
        <GenericAutoComplete
          :items="countries"
          :item-options="{
            displayProperty: 'name',
            filterProperties: ['code', 'name'],
            valueProperty: 'code',
          }"
          :placeholder="'select country'"
          label="Countries"
          class="w-full"
          :shadow="false"
          @update:model-value="(value: any) => addCountry(value)"
        >
        </GenericAutoComplete>

        <div class="w-full">
          <Scrollbar class="pb-3.5">
            <div class="w-full flex gap-1">
              <template
                v-for="country in studyDesign.countries"
                :key="'contry-code' + country"
              >
                <div
                  class="rounded-full dark:text-white text-black text-center max-w-fit border-2 py-1 pr-1.5 pl-1.5 flex items-center whitespace-nowrap"
                >
                  <div
                    class="w-5 h-5 rounded-full text-xs flex items-center justify-center"
                  >
                    <p class="h-3 w-3 font-medium leading-3">
                      {{ country }}
                    </p>
                  </div>
                  <div class="w-5 h-5 flex items-center justify-center">
                    <button
                      class="hover:bg-transparent"
                      @click.stop="removeCountry(country)"
                    >
                      <XMarkIcon class="h-[16.5px]" />
                    </button>
                  </div>
                </div>
              </template>
            </div>
          </Scrollbar>
        </div>
      </div>

      <div>
        <GenericAutoComplete
          :model-value="studyDesign.direction"
          placeholder="select a direction"
          label="Direction"
          :items="directions"
          :item-options="{
            displayProperty: 'text',
            filterProperties: ['text', 'value'],
            valueProperty: 'value',
          }"
          @update:model-value="
            (value: any) => updateStudyDesignProperty('direction', value)
          "
        />
      </div>
      <div v-if="studyDesign.direction !== StudyDesignDirection.Retrospective">
        <GenericAutoComplete
          :model-value="studyDesign.type"
          placeholder="select a type"
          label="Type"
          :items="types"
          :item-options="{
            displayProperty: 'text',
            filterProperties: ['text', 'value'],
            valueProperty: 'value',
          }"
          @update:model-value="
            (value: any) => updateStudyDesignProperty('type', value)
          "
        />
      </div>

      <TextInput
        ref="studyDesignInput"
        v-model="studyDesign.design"
        type="text"
        label="Design"
        placeholder="Study design"
        @keydown.enter="
          () => updateStudyDesignProperty('design', studyDesign.design)
        "
      />

      <div class="flex gap-4">
        <h3 class="text-sm text-primary flex items-center gap-2">Controlled</h3>
        <Switch
          v-model="studyDesign.controlled"
          @update:model-value="
            (value: boolean) => updateStudyDesignProperty('controlled', value)
          "
        />
      </div>

      <div class="space-y-2">
        <h3 class="text-sm text-primary">Exclusion criteria</h3>

        <div class="flex flex-wrap gap-2 items-center">
          <div
            v-for="criterion in studyDesign.exclusionCriteria"
            :key="'studyDesign-exclusion-criterion-' + criterion"
            class="flex items-center text-sm gap-1 px-3 py-1 min-h-6 rounded-full text-green-800 bg-green-200 leading-none"
          >
            {{ criterion }}
            <button @click="removeCriterion('exclusionCriteria', criterion)">
              <XCircleIcon class="w-5 h-5" />
            </button>
          </div>
          <FloatingTextInput @save="addCriterion('exclusionCriteria', $event)">
            <template #activator="{ show }">
              <button
                key=""
                class="text-sm border-2 p-1 px-3 rounded-full text-primary border-blue-200 border-dashed"
                @click.stop="show"
              >
                + Add criterion
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>

      <div class="space-y-2">
        <h3 class="text-sm text-primary">Inclusion criteria</h3>

        <div class="flex flex-wrap gap-2 items-center">
          <div
            v-for="criterion in studyDesign.inclusionCriteria"
            :key="'studyDesign-inclusion-criterion-' + criterion"
            class="flex items-center text-sm gap-1 px-3 py-1 min-h-6 rounded-full text-green-800 bg-green-200 leading-none"
          >
            {{ criterion }}
            <button @click="removeCriterion('inclusionCriteria', criterion)">
              <XCircleIcon class="w-5 h-5" />
            </button>
          </div>
          <FloatingTextInput @save="addCriterion('inclusionCriteria', $event)">
            <template #activator="{ show }">
              <button
                key=""
                class="text-sm border-2 p-1 px-3 rounded-full text-primary border-blue-200 border-dashed"
                @click.stop="show"
              >
                + Add criterion
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>

      <div class="space-y-2">
        <h3 class="text-sm text-primary">Primary outcome</h3>

        <div class="flex flex-wrap gap-2 items-center">
          <div
            v-for="criterion in studyDesign.primaryOutcome"
            :key="'studyDesign-primary-outcome-' + criterion"
            class="flex items-center text-sm gap-1 px-3 py-1 min-h-6 rounded-full text-green-800 bg-green-200 leading-none"
          >
            {{ criterion }}
            <button @click="removeCriterion('primaryOutcome', criterion)">
              <XCircleIcon class="w-5 h-5" />
            </button>
          </div>
          <FloatingTextInput @save="addCriterion('primaryOutcome', $event)">
            <template #activator="{ show }">
              <button
                key=""
                class="text-sm border-2 p-1 px-3 rounded-full text-primary border-blue-200 border-dashed"
                @click.stop="show"
              >
                + Add outcome
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>

      <div class="space-y-2">
        <h3 class="text-sm text-primary">Secondary outcome</h3>

        <div class="flex flex-wrap gap-2 items-center">
          <div
            v-for="criterion in studyDesign.secondaryOutcome"
            :key="'studyDesign-secondary-outcome-' + criterion"
            class="flex items-center text-sm gap-1 px-3 py-1 min-h-6 rounded-full text-green-800 bg-green-200 leading-none"
          >
            {{ criterion }}
            <button @click="removeCriterion('secondaryOutcome', criterion)">
              <XCircleIcon class="w-5 h-5" />
            </button>
          </div>
          <FloatingTextInput @save="addCriterion('secondaryOutcome', $event)">
            <template #activator="{ show }">
              <button
                key=""
                class="text-sm border-2 p-1 px-3 rounded-full text-primary border-blue-200 border-dashed"
                @click.stop="show"
              >
                + Add outcome
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>

      <div
        v-if="studyDesign.direction !== StudyDesignDirection.Retrospective"
        class="flex gap-4"
      >
        <h3 class="text-sm text-primary flex items-center gap-2">
          Participant blinding
        </h3>
        <Switch
          v-model="studyDesign.participantBlinding"
          @update:model-value="
            (value: any) =>
              updateStudyDesignProperty('participantBlinding', value)
          "
        />
      </div>

      <div
        v-if="studyDesign.direction !== StudyDesignDirection.Retrospective"
        class="flex gap-4"
      >
        <h3 class="text-sm text-primary flex items-center gap-2">
          Researcher blinding
        </h3>
        <Switch
          v-model="studyDesign.researcherBlinding"
          @update:model-value="
            (value: boolean) =>
              updateStudyDesignProperty('researcherBlinding', value)
          "
        />
      </div>

      <div class="flex gap-4">
        <h3 class="text-sm text-primary flex items-center gap-2">
          Analysis blinding
        </h3>
        <Switch
          v-model="studyDesign.analysisBlinding"
          @update:model-value="
            (value: boolean) =>
              updateStudyDesignProperty('analysisBlinding', value)
          "
        />
      </div>

      <div
        v-if="studyDesign.direction !== StudyDesignDirection.Retrospective"
        class="flex gap-4"
      >
        <h3 class="text-sm text-primary flex items-center gap-2">Follow up</h3>
        <Switch
          v-model="studyDesign.followUp"
          @update:model-value="
            (value: boolean) => updateStudyDesignProperty('followUp', value)
          "
        />
      </div>

      <TextInput
        v-if="studyDesign.followUp"
        ref="followUpTimeInput"
        v-model="studyDesign.followUpTime"
        type="text"
        label="Follow up Time"
        placeholder="Follow up Time"
        @keydown.enter="
          () =>
            updateStudyDesignProperty('followUpTime', studyDesign.followUpTime)
        "
      />
    </div>
  </Drawer>
</template>

<script setup lang="ts">
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { Study } from '@core/domain/models/study.model'
import { HttpException } from '@core/exceptions/http.exception'
import { reactive } from 'vue'
import Drawer from '@app/components/Global/Drawer.vue'
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'
import { countries } from '@app/utils/countries'
import { StudyDesignType } from '@core/domain/models/studyDesignType.model'
import { StudyDesignDirection } from '@core/domain/models/studyDesignDirection.model'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import Switch from '@app/components/Global/Switch.vue'
import { StudyDesign } from '@core/domain/models/studyDesign.model'
import deepClone from 'lodash/cloneDeep'
import Scrollbar from '@app/components/Global/Scrollbar.vue'
import XMarkIcon from '@app/components/Icons/XMarkIcon.vue'
import FloatingTextInput from '@app/components/Global/Inputs/FloatingTextInput.vue'
import XCircleIcon from '@app/components/Icons/XCircleIcon.vue'

const props = defineProps<{
  study: Study
  isDrawerOpen: boolean
}>()

const review = injectStrict(ReviewKey)

const originalStudyDesign = deepClone(
  props.study.studyDesign ?? ({} as StudyDesign),
)

const types = [
  {
    text: 'Interventional',
    value: StudyDesignType.Interventional,
  },
  {
    text: 'Observational',
    value: StudyDesignType.Observational,
  },
]

const directions = [
  {
    text: 'Prospective',
    value: StudyDesignDirection.Prospective,
  },
  {
    text: 'Retrospective',
    value: StudyDesignDirection.Retrospective,
  },
  {
    text: 'Combined prospective and retrospective',
    value: StudyDesignDirection.CombinedProspectiveAndRetrospective,
  },
]

const studyDesign = reactive<StudyDesign>({
  type: originalStudyDesign?.type ?? '',
  direction: originalStudyDesign?.direction ?? '',
  design: originalStudyDesign?.design ?? '',
  controlled: originalStudyDesign?.controlled ?? false,
  participantBlinding: originalStudyDesign?.participantBlinding ?? false,
  researcherBlinding: originalStudyDesign?.researcherBlinding ?? false,
  analysisBlinding: originalStudyDesign?.analysisBlinding ?? false,
  followUp: originalStudyDesign?.followUp ?? false,
  followUpTime: originalStudyDesign?.followUpTime ?? '',
  countries: originalStudyDesign?.countries ?? [],
  sites: 0,
  exclusionCriteria: originalStudyDesign.exclusionCriteria ?? [],
  inclusionCriteria: originalStudyDesign.inclusionCriteria ?? [],
  primaryOutcome: originalStudyDesign.primaryOutcome ?? [],
  secondaryOutcome: originalStudyDesign.secondaryOutcome ?? [],
})

async function updateStudyDesignProperty<Key extends keyof StudyDesign>(
  key: Key,
  value: StudyDesign[Key],
) {
  studyDesign[key] = value
  await updateStudyDesign(studyDesign)
}

const snackbar = useSnackbar()
const loading = useLoading()

async function updateStudyDesign(studyDesign: StudyDesign) {
  try {
    loading.start()
    await review.updateStudyDesign(props.study.id, studyDesign)
    Object.assign(originalStudyDesign, deepClone(studyDesign))
    snackbar.success('Study design updated successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    Object.assign(studyDesign, deepClone(originalStudyDesign))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

async function addCountry(countryCode: string) {
  if (!studyDesign.countries.includes(countryCode)) {
    studyDesign.countries.push(countryCode)
    await updateStudyDesign(studyDesign)
  }
}

async function removeCountry(countryCode: string) {
  const index = studyDesign.countries.indexOf(countryCode)

  if (index !== -1) {
    studyDesign.countries.splice(index, 1)
    await updateStudyDesign(studyDesign)
  }
}

async function addCriterion(
  key:
    | 'exclusionCriteria'
    | 'inclusionCriteria'
    | 'primaryOutcome'
    | 'secondaryOutcome',
  criterion: string,
) {
  if (!studyDesign[key].includes(criterion)) {
    studyDesign[key].push(criterion)
    await updateStudyDesign(studyDesign)
  }
}

async function removeCriterion(
  key:
    | 'exclusionCriteria'
    | 'inclusionCriteria'
    | 'primaryOutcome'
    | 'secondaryOutcome',
  criterion: string,
) {
  const index = studyDesign[key].indexOf(criterion)
  if (index > -1) {
    studyDesign[key].splice(index, 1)
    await updateStudyDesign(studyDesign)
  }
}
</script>
